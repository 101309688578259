import Section from '@/layouts/section'
import Block from '@/layouts/block'
import { H1, H2 } from '@/components/typography'
import { Asset } from '@/interfaces/schema'
import getBgImgStyles from '@/lib/bg-image'
import { useExtraSmallScreen, useMediumScreen } from '@/hooks/useMediaQuery'
import { GRID } from '@/lib/constants'

interface BannerMediumProps {
  bannerImage?: Asset
  bannerTitle?: string
  bannerSubtitle?: string
}

export const BannerMedium = ({
  bannerImage,
  bannerTitle = '',
  bannerSubtitle = '',
}: BannerMediumProps) => {
  const isExtraSmallScreen = useExtraSmallScreen()
  const isMediumScreen = useMediumScreen()
  const imgHeight = isExtraSmallScreen ? 215 : 440
  const imgWidth = isMediumScreen ? 'calc(100% + 10px)' : 'calc(100% + 80px)'

  const bg = getBgImgStyles({
    url: bannerImage?.url ?? '',
    height: imgHeight,
    width: imgWidth,
    parallax: false,
  })

  const mbMobile = bannerTitle ? 'mb-[90px]' : 'mb-[50px]'

  return (
    <Section
      styles={bg}
      className={`relative rounded-xs md:rounded-sm w-full !max-w-[1360px] object-top lg:mb-[120px] ${mbMobile}`}
    >
      {bannerTitle && (
        <Block className={`${GRID}`}>
          <div className="flex flex-col justify-center mx-[10px] md:mx-[20px] lg:mx-[40px] h-[123px] md:h-[145px] lg:h-[190px] col-span-full lg:col-start-1 lg:col-end-7 mt-[170px] sm:mt-[331px] rounded-xs md:rounded-sm bg-neutral-white px-[30px] sm:px-10 border-2 border-neutrals-100">
            <H1>{bannerTitle}</H1>
            {bannerSubtitle && <H2 className="mt-[5px]">{bannerSubtitle}</H2>}
          </div>
        </Block>
      )}
    </Section>
  )
}
