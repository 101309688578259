import { Fragment, useId, useState } from 'react'

import { AngleDownButton, AngleUpButton } from '@/components/button'
import { H4 } from '@/components/typography'
import { Faq, FaqAnswer } from '@/interfaces/schema'
import RichTextRenderer from '@/services/contentful/utils/richText'

type AccordionProps = {
  className?: string
  faqsCollection?: {
    items?: Faq[]
  }
}

interface AccordionItemProps {
  question: string | undefined
  answer: FaqAnswer | undefined
  index: number
  activeItemIndex: number | null
  toggleAccItem: (index: number) => void
}

export const Accordion = ({
  className = '',
  faqsCollection,
}: AccordionProps) => {
  const [activeItemIndex, setActiveItemIndex] = useState<number | null>(-1)
  const id = useId()

  const toggleAccItem = (index: number) => {
    if (index === activeItemIndex) {
      return setActiveItemIndex(null)
    }
    setActiveItemIndex(index)
  }

  return (
    <div className={`accordion ${className}`}>
      {faqsCollection?.items?.map(({ question, answer }, i: number) => (
        <Fragment key={`${id}_${question ?? ''}_${i}`}>
          <AccordionItem
            index={i}
            question={question}
            answer={answer}
            activeItemIndex={activeItemIndex}
            toggleAccItem={toggleAccItem}
          />
        </Fragment>
      ))}
    </div>
  )
}

const AccordionItem = ({
  index,
  question,
  answer,
  toggleAccItem,
  activeItemIndex,
}: AccordionItemProps) => {
  if (!question || !answer) return <></>

  const isActive = activeItemIndex === index

  return (
    <div className="accordion__item">
      <button
        className="item__question"
        onClick={() => toggleAccItem(index)}
      >
        <H4>{question}</H4>
        {isActive ? (
          <AngleUpButton onClick={() => toggleAccItem(index)} />
        ) : (
          <AngleDownButton onClick={() => toggleAccItem(index)} />
        )}
      </button>
      {isActive && (
        <RichTextRenderer
          className="item__answer"
          richText={answer}
        />
      )}
    </div>
  )
}
