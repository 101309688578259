import { Accordion } from '@/components/accordion'
import IconWithTitle from '@/components/icon-with-title'
import { P2 } from '@/components/typography'
import { Faq, HomePageRay } from '@/interfaces/schema'
import Section from '@/layouts/section'

type FaqSectionProps = Pick<
  HomePageRay,
  'faqIcon' | 'faqTitle' | 'faqSubtitle'
> & {
  faqsCollection?: {
    items?: Faq[]
  }
}

export const FaqSection = ({
  faqIcon,
  faqTitle,
  faqSubtitle,
  faqsCollection,
}: FaqSectionProps) => {
  if (!faqsCollection?.items) return <></>

  return (
    <Section id="faq" className="grid w-full mb-[30px] lg:mb-[120px] pb-0 ">
      <IconWithTitle title={faqTitle} icon={faqIcon} />
      {faqSubtitle && <P2>{faqSubtitle}</P2>}
      <Accordion
        className="mt-[30px] lg:mt-[50px] col-span-full"
        faqsCollection={faqsCollection}
      />
    </Section>
  )
}
