import { ButtonLinkPrimaryTextAndArrow } from '@/components/button'
import { H2, P1 } from '@/components/typography'
import { RichTextProps } from '@/interfaces/richText'
import Block from '@/layouts/block'
import Section from '@/layouts/section'
import { GRID, GRID_ITEM_FULL_WIDTH } from '@/lib/constants'
import RichTextRenderer from '@/services/contentful/utils/richText'

interface RichTextBlockProps {
  title?: string | undefined
  richTextTitle?: RichTextProps | undefined
  content: RichTextProps | undefined
  className?: string
  actionBtnLink?: string
  actionBtnText?: string
}

export default function RichTextBlockSection({
  title = '',
  richTextTitle,
  content = {} as RichTextProps,
  className = '',
  actionBtnLink,
  actionBtnText,
}: RichTextBlockProps) {
  return (
    <Section className={`richTextBlockSection flex flex-col gap-14 w-full ${className}`}>
      <Block className={`${GRID} ${GRID_ITEM_FULL_WIDTH}`}>
        {richTextTitle ? (
          <RichTextRenderer
            richText={richTextTitle}
            className="richTextBlockSection__title-richtext col-span-2 md:col-start-1 md:col-end-3 lg:col-start-1 lg:col-end-5"
          />
        ) : (
          <H2 className="col-span-2 md:col-start-1 md:col-end-3 lg:col-start-1 lg:col-end-5">
            {title}
          </H2>
        )}
        <Block className="col-span-2 md:col-start-3 md:col-end-7 lg:col-start-5 lg:col-end-13">
          <RichTextRenderer richText={content} />
          {actionBtnText && actionBtnLink && (
            <ButtonLinkPrimaryTextAndArrow
              className=" ml-0 mr-auto"
              external
              href={actionBtnLink}
            >
              <P1>{actionBtnText}</P1>
            </ButtonLinkPrimaryTextAndArrow>
          )}
        </Block>
      </Block>
    </Section>
  )
}
