import { useSmallScreen } from '@/hooks/useMediaQuery'
import { Asset } from '@/interfaces/schema'
import Block from '@/layouts/block'
import Image from '@/components/image'
import { ButtonLinkPrimaryTextAndArrow } from '@/components/button'

interface IconWithTitleProps {
  className?: string
  icon: Asset | undefined
  title?: string
  showAllButtonUrl?: string
}

const IconWithTitle = ({
  className = '',
  icon,
  title,
  showAllButtonUrl,
}: IconWithTitleProps) => {
  const isSmallScreen = useSmallScreen()
  const headerClass = isSmallScreen ? 'H1' : 'H2'

  return (
    <Block
      className={`${className} col-span-full flex md:items-center lg:items-start`}
    >
      {icon && (
        <Image
          src={icon?.url ?? ''}
          height={isSmallScreen ? 30 : 40}
          width={isSmallScreen ? 18 : 30}
          objectFit="contain"
          alt=""
          className="w-[30px] md:w-[70px]"
        />
      )}
      {title && <h2 className={headerClass}>{title}</h2>}
      {showAllButtonUrl && (
        <ButtonLinkPrimaryTextAndArrow
          href={showAllButtonUrl}
          className="ml-auto"
        >
          Naar volledig aanbod
        </ButtonLinkPrimaryTextAndArrow>
      )}
    </Block>
  )
}

export default IconWithTitle
