import { Asset, Story } from './schema'

export const tabsContentObj: StoryTypeValues = {
  ALL: 'Alle',
  BLOG: 'Blog',
  CASE: 'Case',
  NEWS: 'Nieuws',
  PODCAST: 'Podcasts',
  TESTIMONIAL: 'Testimonials',
  WHITEPAPER: 'Whitepapers',
}

export const pinnedStoryObj: StoryTypeValues = {
  ALL: 'pinnedStoryAll',
  BLOG: 'pinnedStoryBlog',
  CASE: 'pinnedStoryCase',
  NEWS: 'pinnedStoryNews',
  PODCAST: 'pinnedStoryPodcast',
  TESTIMONIAL: 'pinnedStoryTestimonial',
  WHITEPAPER: 'pinnedStoryWhitepaper',
}

export type StoryTypeNames =
  | 'ALL'
  | 'BLOG'
  | 'CASE'
  | 'NEWS'
  | 'PODCAST'
  | 'TESTIMONIAL'
  | 'WHITEPAPER'

export type StoryTypeValues = Record<StoryTypeNames, string>

export type TabsContentKeys = keyof typeof tabsContentObj

export type TabsContentValues = typeof tabsContentObj[TabsContentKeys]

export type PinnedStoryKeys = keyof typeof pinnedStoryObj

export type PinnedStoryValues = typeof pinnedStoryObj[PinnedStoryKeys]

export type StoryCardProps = Pick<Story, 'title' | 'description'> & {
  className?: string
  publicationDate: Date
  buttonLink?: string
}

export enum StoryCardWithImgPosition {
  TOP = 'top',
  RIGHT = 'right',
  LEFT = 'left',
}

export type StoryCardWithImageProps = Pick<Story, 'title' | 'description'> & {
  publicationDate?: Date | null
  className?: string
  imgPosition: StoryCardWithImgPosition
  image: Omit<Asset, 'sys' | 'contentfulMetadata' | '_id'> | undefined
  buttonText?: string
  buttonLink?: string
}

export type StoryCollectionQuery = {
  storyCollection: {
    items: Story[]
    total: number
  }
}
