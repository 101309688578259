import { CSSProperties } from 'react'

import { Maybe } from '@/interfaces/schema'

enum BackgroundPostition {
  TOP = 'top',
  CENTER = 'center',
}

interface BackgroundImage {
  url: string
  width?: Maybe<number | string> | undefined
  height?: Maybe<number | string> | undefined
  parallax?: boolean
  gradientOverlay?: boolean
  backgroundPosition?: BackgroundPostition
}

const getBgImgStyles = ({
  url = '',
  width,
  height,
  parallax = true,
  gradientOverlay = false,
  backgroundPosition = BackgroundPostition.CENTER,
}: BackgroundImage): CSSProperties => {
  const fullWidth = typeof window !== 'undefined' ? window.innerWidth : 1440
  const fullHeight = typeof window !== 'undefined' ? window.innerHeight : 900
  const mask = `linear-gradient(90deg, rgba(9, 197, 145, 1), rgba(9, 197, 145, 0) 50%), url(${url})`
  const bg = gradientOverlay ? mask : `url(${url})`

  return {
    backgroundImage: bg,
    backgroundAttachment: parallax ? 'fixed' : 'unset',
    backgroundRepeat: ' no-repeat',
    backgroundPosition: backgroundPosition,
    backgroundSize: 'cover',
    width: width ?? fullWidth,
    height: height ?? fullHeight,
    objectFit: 'cover',
  }
}

export default getBgImgStyles


export const vacancyBannerImages = [
  {
    src: '/images/vacancy-banner/image-1.jpg',
    title: 'Word HR Medewerker bij Ons Dynamisch Team!',
    description: 'Levendige afbeelding van HR-medewerkers in actie. Perfect voor een vacaturepagina, met focus op teamwork en carrièremogelijkheden.'
  },
  {
    src: '/images/vacancy-banner/image-2.jpg',
    title: 'Vacature: Ervaren HR Adviseur Gezocht',
    description: 'Ontdek deze inspirerende afbeelding van HR-adviseurs die samenwerken. Ideaal voor een vacaturepagina, het toont teamgeest en groeikansen.'
  },
  {
    src: '/images/vacancy-banner/image-3.jpg',
    title: 'HR Business Partner: Jouw Volgende Carrièrestap',
    description: 'Deze dynamische afbeelding toont HR Business Partners aan het werk. Perfect voor uw vacaturepagina, met nadruk op samenwerking en carrièrekansen.'
  },
  {
    src: '/images/vacancy-banner/image-4.jpg',
    title: 'Gezocht: Leiderschap als HR Manager',
    description: 'Afbeelding van HR-managers die samenwerken, ideaal voor een vacaturepagina. Laat de teamspirit en groeimogelijkheden zien.'
  },
  {
    src: '/images/vacancy-banner/image-5.jpg',
    title: 'Word Onze Nieuwe Recruiter!',
    description: 'Levendige afbeelding van recruiters in actie, perfect voor een vacaturepagina. Visualiseert teamwork en carrièremogelijkheden.'
  },
  {
    src: '/images/vacancy-banner/image-6.jpg',
    title: 'Versterk Ons Team als HR Medewerker',
    description: 'HR-medewerkers aan het werk in een levendige setting. Ideaal voor uw vacaturepagina, benadrukt teamgeest en carrièregroei.'
  },
  {
    src: '/images/vacancy-banner/image-7.jpg',
    title: 'Vacature: HR Adviseur met Passie voor Mensen',
    description: 'Inspirerende afbeelding van HR-adviseurs die samenwerken, perfect voor een vacaturepagina. Visualiseert teamgeest en groeimogelijkheden.'
  },
  {
    src: '/images/vacancy-banner/image-8.jpg',
    title: 'Ontwikkel je Carrière als HR Business Partner',
    description: 'HR Business Partners in actie, ideaal voor uw vacaturepagina. Een visuele weergave van samenwerking en carrièregroei.'
  },
  {
    src: '/images/vacancy-banner/image-9.jpg',
    title: 'HR Manager: Leid Onze Groeiende Organisatie',
    description: 'Afbeelding van HR-managers die samenwerken, perfect voor een vacaturepagina. Toont teamgeest en biedt een blik op carrièremogelijkheden.'
  },
  {
    src: '/images/vacancy-banner/image-10.jpg',
    title: 'Recruiter Gezocht voor Topbedrijf',
    description: 'Dynamische afbeelding van recruiters die samenwerken, ideaal voor een vacaturepagina. Visualiseert teamspirit en groeikansen.'
  },
  {
    src: '/images/vacancy-banner/image-11.jpg',
    title: 'Kans voor Ambitieuze HR Medewerker',
    description: 'Ontdek deze levendige afbeelding van HR-medewerkers in actie. Perfect voor een vacaturepagina, met focus op samenwerking en carrièrekansen.'
  },
  {
    src: '/images/vacancy-banner/image-12.jpg',
    title: 'HR Adviseur: Bouw mee aan Ons Succes',
    description: 'Inspirerende afbeelding van HR-adviseurs die samenwerken, ideaal voor een vacaturepagina. Toont teamgeest en carrièremogelijkheden.'
  },
  {
    src: '/images/vacancy-banner/image-13.jpeg',
    title: 'HR Adviseur: Bouw mee aan Ons Succes',
    description: 'Inspirerende afbeelding van HR-adviseurs die samenwerken, ideaal voor een vacaturepagina. Toont teamgeest en carrièremogelijkheden.'
  },
  {
    src: '/images/vacancy-banner/image-14.jpeg',
    title: 'HR Adviseur: Bouw mee aan Ons Succes',
    description: 'Inspirerende afbeelding van HR-adviseurs die samenwerken, ideaal voor een vacaturepagina. Toont teamgeest en carrièremogelijkheden.'
  },
  {
    src: '/images/vacancy-banner/image-15.jpeg',
    title: 'HR Adviseur: Bouw mee aan Ons Succes',
    description: 'Inspirerende afbeelding van HR-adviseurs die samenwerken, ideaal voor een vacaturepagina. Toont teamgeest en carrièremogelijkheden.'
  },
];

export const generateRandomVacancyBannerImg = () => {
  const randomIndex = Math.floor(Math.random() * vacancyBannerImages.length)
  const randomBannerImage = vacancyBannerImages[randomIndex]
  return randomBannerImage
}
