import { ButtonLinkPrimary } from '@/components/button'
import IconWithTitle from '@/components/icon-with-title'
import { RichTextProps } from '@/interfaces/richText'
import { Asset } from '@/interfaces/schema'
import Section from '@/layouts/section'
import { GRID } from '@/lib/constants'
import RichTextRenderer from '@/services/contentful/utils/richText'

interface ContentSmallSectionProps {
  contentIcon?: Asset
  contentTitle?: string
  contentBody?: RichTextProps
  buttonText?: string
  buttonPath?: string
  id?: string
}

const ContentSmallSection = ({
  contentIcon,
  contentTitle,
  contentBody,
  buttonText = '',
  buttonPath = '',
  id = '',
}: ContentSmallSectionProps) => {
  return (
    <Section id={id} className={`${GRID} mb-[30px] lg:mb-[120px]`}>
      <IconWithTitle
        className="lg:col-start-1 lg:col-end-5"
        icon={contentIcon}
        title={contentTitle}
      />
      <RichTextRenderer
        className="col-span-full lg:col-start-5 lg:col-end-13"
        richText={contentBody}
      />
      {buttonText && buttonPath && (
        <ButtonLinkPrimary
          href={buttonPath}
          className="col-span-full lg:col-start-5 lg:col-end-13 !w-fit"
        >
          {buttonText}
        </ButtonLinkPrimary>
      )}
    </Section>
  )
}

export default ContentSmallSection
