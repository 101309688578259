import Section from '@/layouts/section'
import Block from '@/layouts/block'
import { ButtonLinkPrimaryArrow } from '@/components/button'
import { P1 } from '@/components/typography'
import { HomePageRay, Story } from '@/interfaces/schema'
import { StoryCardWithImgPosition } from '@/interfaces/stories'
import StoryCardWithImage from '@/components/cards/stories/with-image'
import { StoryCard } from '@/components/cards/stories/without-image'
import IconWithTitle from '@/components/icon-with-title'
import { routes } from '@/lib/constants'
import useIsRay from '@/hooks/useIsRay'

type StoriesSectionProps = Pick<
  HomePageRay,
  'storiesSectionIcon' | 'storiesSectionTitle' | 'storiesButtonText'
> & {
  storiesCollection?: {
    items?: Story[]
  }
}

export const StoriesSection = ({
  storiesSectionIcon,
  storiesSectionTitle,
  storiesCollection,
  storiesButtonText,
}: StoriesSectionProps) => {
  const isRay = useIsRay()

  if (!storiesCollection?.items) return <></>

  const firstStory = storiesCollection?.items[0] as unknown as Story
  const secondStory = storiesCollection?.items[1] as unknown as Story
  const thirdStory = storiesCollection?.items[2] as unknown as Story

  const renderRoute = isRay ? routes.ray : routes.rvdb

  return (
    <Section className="grid grid-cols-2 md:grid-cols-6 lg:grid-cols-12 gap-lg place-items-center mb-[30px] lg:mb-[120px]">
      <Block className="flex flex-col gap-4 lg:gap-9 col-start-1 col-end-3 md:col-start-1 md:col-end-7">
        <IconWithTitle
          icon={storiesSectionIcon}
          title={storiesSectionTitle}
          className="col-start-1 col-span-7"
        />
        <StoryCardWithImage
          title={firstStory.title}
          publicationDate={firstStory.publicationDate}
          buttonLink={`${renderRoute.knowledgeAndInspiration}/${firstStory.slug}`}
          image={firstStory.image}
          description={firstStory.description}
          imgPosition={StoryCardWithImgPosition.TOP}
        />
      </Block>
      <Block className="w-full flex flex-col gap-4 lg:gap-9 self-end row-start-2 lg:row-start-1 col-start-1 col-end-3 md:col-start-1 md:col-end-7 lg:col-start-7 lg:col-end-13">
        <div className="flex justify-end w-full">
          <span className="flex items-center justify-between min-w-[240px]">
            <P1>{storiesButtonText}</P1>
            <ButtonLinkPrimaryArrow
              href={renderRoute.knowledgeAndInspiration}
              className="ml-5"
            />
          </span>
        </div>
        <div className="hidden md:flex flex-col gap-4 lg:gap-9">
          <StoryCard
            title={secondStory.title}
            publicationDate={secondStory.publicationDate}
            buttonLink={`${renderRoute.knowledgeAndInspiration}/${secondStory.slug}`}
          />
          <StoryCard
            title={thirdStory.title}
            publicationDate={thirdStory.publicationDate}
            buttonLink={`${renderRoute.knowledgeAndInspiration}/${thirdStory.slug}`}
          />
        </div>
      </Block>
    </Section>
  )
}
