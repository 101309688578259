import { useId } from 'react'

import IconWithTitle from '@/components/icon-with-title'
import { ConsultancyOverview } from '@/interfaces/schema'
import Section from '@/layouts/section'
import Slider from 'react-slick'
import { useMediumScreen, useSmallScreen } from '@/hooks/useMediaQuery'
import Image from '@/components/image'
import { Divider, DividerColors } from '@/components/divider'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'

type Props = Pick<
  ConsultancyOverview,
  'clientsSectionIcon' | 'clientsSectionTitle' | 'clientsList'
> & { id?: string }

const ClientsSection = ({
  clientsSectionIcon,
  clientsSectionTitle,
  clientsList,
  id = '',
}: Props) => {
  const clientLogoId = useId()
  const isSmallScreen = useSmallScreen()
  const isMediumScreen = useMediumScreen()

  const settings = {
    autoplay: true,
    infinite: true,
    speed: 500,
    slidesToShow: isSmallScreen ? 2 : isMediumScreen ? 3 : 4,
    slidesToScroll: 1,
  }

  return (
    <Section id={id} className="mb-[30px] lg:mb-[120px]">
      <IconWithTitle
        className="lg:mb-[30px]"
        icon={clientsSectionIcon}
        title={clientsSectionTitle}
      />
      <Divider color={DividerColors.BLACK} />
      <Slider
        {...settings}
        className="flex items-center py-[25px] lg:py-[55px] "
      >
        {clientsList?.clientLogosCollection?.items.map((asset, index) => (
          <div
            key={`${clientLogoId}-${index}`}
            className="w-min flex justify-center items-center "
          >
            <Image
              className="clientImage"
              objectFit="contain"
              src={asset?.url ?? ''}
              alt={asset?.description ?? ''}
              width={160}
              height={100}
              key={`${id}-${index}`}
            />
          </div>
        ))}
      </Slider>
      <Divider color={DividerColors.BLACK} />
    </Section>
  )
}

export default ClientsSection
