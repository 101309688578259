import Slider from 'react-slick'
import { useId } from 'react'

import CTAReviewCard from '@/components/cards/cta/review'
import IconWithTitle from '@/components/icon-with-title'
import { Asset, Testimonial } from '@/interfaces/schema'
import Section from '@/layouts/section'
import { GRID } from '@/lib/constants'
import RateCard from '@/components/ratecard'

interface Props {
  id?: string
  reviewSectionIcon?: Asset
  reviewSectionTitle?: string
  testimonialsCollection?: {
    items: Testimonial[]
  }
}

const ReviewSection = ({
  id = '',
  reviewSectionIcon,
  reviewSectionTitle,
  testimonialsCollection,
}: Props) => {
  const reviewCardId = useId()

  const settings = {
    autoplay: true,
    infinite: true,
    speed: 750,
    slidesToShow: 1,
    slidesToScroll: 1,
  }

  return (
    <Section id={id} className={`${GRID} mb-[30px] lg:mb-[120px]`}>
      <IconWithTitle icon={reviewSectionIcon} title={reviewSectionTitle} />
      <Slider {...settings} className="col-span-full lg:col-span-8">
        {testimonialsCollection?.items.map((testimonial, index) => (
          <CTAReviewCard
            key={`${reviewCardId}-${index}`}
            image={testimonial?.image}
            title={testimonial?.quote ?? ''}
            name={testimonial?.name ?? ''}
            position={testimonial?.position ?? ''}
          />
        ))}
      </Slider>
      <RateCard className="col-span-full lg:col-span-4" />
    </Section>
  )
}

export default ReviewSection
