import IconWithTitle from '@/components/icon-with-title'
import { H4 } from '@/components/typography'
import { RichTextProps } from '@/interfaces/richText'
import { Asset } from '@/interfaces/schema'
import Block from '@/layouts/block'
import Section from '@/layouts/section'
import { GRID } from '@/lib/constants'
import RichTextRenderer from '@/services/contentful/utils/richText'

interface IntroSectionProps {
  introductionIcon?: Asset
  introductionTitle?: string
  introductionContent?: RichTextProps
  id?: string
}

const IntroductionSection = ({
  introductionIcon,
  introductionTitle,
  introductionContent,
  id,
}: IntroSectionProps) => {
  return (
    <Section id={id} className={`${GRID} gap-y-[10px] mb-[30px] lg:mb-[120px]`}>
      <IconWithTitle
        className="lg:col-start-1 lg:col-end-5"
        icon={introductionIcon}
        title={introductionTitle}
      />
      <Block className="col-span-full lg:col-start-5 lg:col-end-13">
        <RichTextRenderer
          className="ml-[30px] lg:ml-auto"
          richText={introductionContent}
        />
      </Block>
    </Section>
  )
}

export default IntroductionSection
