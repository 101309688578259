import Block from '@/layouts/block'
import Script from 'next/script'

const RateCard = ({ className = '' }: { className?: string }) => {
  return (
    <Block className={className}>
      <Script src="https://ratecard.io/widgets/js/slider.js" async></Script>
      <div
        className="ratecard-widget--slider"
        id="2B1xoPQX0LQyYDNv"
        data-locale="nl"
        data-form-of-address="formal"
        data-color="#5ec5a4"
        data-metric="csat"
        data-items="15"
        data-cta="no"
      ></div>
    </Block>
  )
}

export default RateCard
