import {
  ButtonLinkPrimaryArrow,
  ButtonLinkPrimaryTextAndArrow,
} from '@/components/button'
import { H1, P1 } from '@/components/typography'
import { Scalars } from '@/interfaces/schema'
import Block from '@/layouts/block'
import Section from '@/layouts/section'
import { GRID } from '@/lib/constants'
import RichTextRenderer from '@/services/contentful/utils/richText'

interface Props {
  title?: string
  content?: { json: Scalars['JSON'] }
  backBtnLink: string
  backBtnText: string
  actionBtnLink?: string
  actionBtnText?: string
}

const ContentLargeSection = ({
  title = '',
  content,
  backBtnLink,
  backBtnText,
  actionBtnLink,
  actionBtnText,
}: Props) => {
  return (
    <Section className={`${GRID} mb-[100px]`}>
      <Block className="col-span-full lg:col-start-1 lg:col-end-4 self-start mt-[30px] lg:mt-[100px] flex items-center">
        <ButtonLinkPrimaryArrow
          href={backBtnLink}
          className="mr-4 rotate-180"
        />
        <P1>{backBtnText}</P1>
      </Block>
      <Block className="contentLarge lg:mt-[-100px] p-10 z-20 col-span-full lg:col-start-4 lg:col-end-13">
        <H1 className="mb-10">{title}</H1>
        <RichTextRenderer richText={content} />
        {actionBtnText && actionBtnLink && (
          <ButtonLinkPrimaryTextAndArrow
            className="contentLargeButton ml-auto"
            external
            href={actionBtnLink}
          >
            <P1>{actionBtnText}</P1>
          </ButtonLinkPrimaryTextAndArrow>
        )}
      </Block>
    </Section>
  )
}

export default ContentLargeSection
